import React, { useEffect, useState } from "react";
import AOS from "aos";

import { HelmetProvider } from "react-helmet-async";

import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";

import seoImage from "../../static/seo/seo_image.png";
import Hero from "../images/thankyou/hero.png";

import {
  HeroContainer,
  InternalWrapper,
  ImageDiv,
  HeroTextDiv,
  Title,
  Paragraph,
  PhoneAndParagraph,
  ButtonHomepage,
} from "../styles/pages/thankyou.jsx";
import { PATHS } from "../config/urls.jsx";

const ThankYou = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(
    () => {
      AOS.init({
        // initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  useEffect(() => {
    setIsClient(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout className="thankYouLayout">
        <Seoinfo
          siteName="Compassionate Care in the Comfort of Your Home | Life Home Care"
          titlePage="Compassionate Care in the Comfort of Your Home | Life Home Care"
          description="Our goal is to quality of life and promote independence for our clients by providing compassionate care. Life is the top home care   agency in the area."
          keywords="Home Care Services,Senior Care,In-Home Healthcare,Elderly Assistance,Personal Care Services"
          type="website"
          url="/thankyou"
          imageFacebook={seoImage}
          canonical
        />
        <HeroContainer>
          <InternalWrapper data-aos="fade">
            <ImageDiv bgImage={Hero} alt="Nurse looking at patient" />
            <HeroTextDiv>
              <Title>Thanks for reaching out!</Title>
              <PhoneAndParagraph>
                <Paragraph>
                  A member of our team will be in touch with you shortly.
                </Paragraph>
                <ButtonHomepage href={PATHS.INDEX}>
                  BACK TO LIFE HOMECARE
                </ButtonHomepage>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
      </Layout>
    </HelmetProvider>
  );
};

export default ThankYou;
