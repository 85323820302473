import styled from "styled-components";

import { COLORS } from "../globals/colors";
import {
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_768,
  MOBILE_460,
  TABLET_600,
} from "../globals/sizes";

export const HeroContainer = styled.div`
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1500px;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${TABLET_768}px) {
    background: white;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const TabletDiv = styled.div`
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 100% 95%/cover no-repeat`
      : `background: purple`};
  @media (max-width: ${TABLET_768}px) {
    display: block;
    background-position: 80%;
  }
  @media (max-width: 600px) {
    height: 330px;
    background-position: 80%;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`;

export const ImageDiv = styled.div`
  width: 100%;
  height: 506px;
  background: ${(props) =>
    props.bgImage ? `url(${props.bgImage}) 10% 45%/cover no-repeat` : `purple`};
  @media (max-width: ${DESKTOP_1200}px) {
    height: 400px;
    background-position: center;
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  width: 100%;
  max-width: 1500px;
  flex: 1;

  @media (max-width: ${TABLET_768}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }
`;

export const HeroTextDiv = styled.div`
  padding: 90px 30px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: ${TABLET_992}px) {
    padding: 50px 20px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  width: 100%;
  margin: 0 auto 20px;
  color: ${COLORS.redTitle};
  text-align: center;
  font: 700 50px / normal Roboto;
  color: #ec4746;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }
`;

export const Paragraph = styled.p`
  width: 100%;
  margin: 0 auto 50px;
  color: ${COLORS.redTitle};
  text-align: center;
  font: 500 30px / normal Roboto;

  @media (max-width: ${TABLET_600}px) {
    width: 90%;
  }
`;

export const PhoneAndParagraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonHomepage = styled.a`
  width: 229px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 22px;
  background-color: ${COLORS.redTitle};
  color: ${COLORS.white};
  text-align: center;
  font-family: "Nunito";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
